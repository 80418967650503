import { Input } from "@nextui-org/input";
import { Card, CardBody, CardHeader, Divider, Link, Skeleton } from "@nextui-org/react";
import {
  CalculatorIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import "@oysterhr/web-components";

export const ADP = () => {
  return (
    <div>
      <section className="-mb-24">
        <header className="bg-blue-950 h-96 flex justify-center items-center">
          <div className="w-full max-w-lg flex flex-col items-center gap-5">
            <h1 className="text-white font-medium text-xl">Good morning, Alex</h1>
            <Input
              radius="full"
              type="search"
              placeholder="How can we help today?"
              startContent={<MagnifyingGlassIcon className="w-5" />}
            />
            <ul className="flex gap-6">
              <li className="text-white flex flex-col items-center text-xs gap-2">
                <div className="bg-blue-800 rounded-full size-12 flex justify-center items-center">
                  <CurrencyDollarIcon className="rounded-full size-6" />
                </div>
                New payroll
              </li>
              <li className="text-white flex flex-col items-center text-xs gap-2">
                <div className="bg-blue-800 rounded-full size-12 flex justify-center items-center">
                  <UserPlusIcon className="rounded-full size-6" />
                </div>
                Add new Employee
              </li>
            </ul>
          </div>
        </header>
      </section>
      <section className="p-12">
        <div className="grid grid-cols-[600px_1fr] gap-6 mb-6">
          <Card>
            <CardHeader>
              <h2 className="font-medium">Upcoming payroll</h2>
            </CardHeader>
            <Divider />
            <CardBody>
              <Skeleton className="rounded-lg">
                <div className="h-24 rounded-lg bg-default-300"></div>
              </Skeleton>
              <div className="space-y-3 mt-4">
                <Skeleton className="w-3/5 rounded-lg">
                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-4/5 rounded-lg">
                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                </Skeleton>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h2 className="font-medium">Top to-dos</h2>
            </CardHeader>
            <Divider />
            <CardBody>
              <Skeleton className="rounded-lg">
                <div className="h-24 rounded-lg bg-default-300"></div>
              </Skeleton>
              <div className="space-y-3 mt-4">
                <Skeleton className="w-3/5 rounded-lg">
                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-4/5 rounded-lg">
                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                </Skeleton>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <Card>
            <CardHeader>
              <h2 className="font-medium">Grow your business</h2>
            </CardHeader>
            <Divider />
            <CardBody className="flex gap-4">
              <div className="flex gap-4 items-center">
                <DocumentTextIcon className="size-8 bg-blue-200 rounded-full p-1" />
                <div>
                  <h3 className="font-semibold">HR</h3>
                  <p className="text-sm">Handbooks and hiring</p>
                </div>
              </div>
              <Link href="/adp/hire" className="flex gap-4 items-center text-black">
                <GlobeAltIcon className="size-8 bg-blue-200 rounded-full p-1" />
                <div>
                  <h3 className="font-semibold">Hiring internationally</h3>
                  <p className="text-sm">Start hiring via Oyster</p>
                </div>
              </Link>
              <Link href="/adp/cost-calculator" className="flex gap-4 items-center text-black">
                <CalculatorIcon className="size-8 bg-blue-200 rounded-full p-1" />
                <div>
                  <h3 className="font-semibold">Cost calculator</h3>
                  <p className="text-sm">Calculate the total cost of employment</p>
                </div>
              </Link>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h2 className="font-medium">Recent payroll</h2>
            </CardHeader>
            <Divider />
            <CardBody>
              <Skeleton className="rounded-lg">
                <div className="h-24 rounded-lg bg-default-300"></div>
              </Skeleton>
              <div className="space-y-3 mt-4">
                <Skeleton className="w-3/5 rounded-lg">
                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-4/5 rounded-lg">
                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                </Skeleton>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h2 className="font-medium">Calendar</h2>
            </CardHeader>
            <Divider />
            <CardBody>
              <Skeleton className="rounded-lg">
                <div className="h-24 rounded-lg bg-default-300"></div>
              </Skeleton>
              <div className="space-y-3 mt-4">
                <Skeleton className="w-3/5 rounded-lg">
                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-4/5 rounded-lg">
                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                </Skeleton>
              </div>
            </CardBody>
          </Card>
        </div>
      </section>
    </div>
  );
};

import { Link } from "@nextui-org/react";
import "@oysterhr/web-components";
import { Outlet } from "react-router-dom";

export const ADPLayout = () => {
  return (
    <main className="grid grid-cols-[180px_1fr] min-h-dvh bg-slate-100">
      <nav className="bg-blue-900 p-6">
        <Link href="/adp">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 70 32"
            className="size-20"
          >
            <defs></defs>
            <title>Artboard 1</title>
            <path
              className="fill-white"
              d="M44,15.9A9.13,9.13,0,0,1,34.9,25H31.4V21h3.5a5.1,5.1,0,1,0,0-10.2H31.4v-4h3.5A9.13,9.13,0,0,1,44,15.9M24.6,6.8v7.4H18.3l-2.3,4H28.6V6.8ZM70,12.5A12.5,12.5,0,0,1,57.5,25v6.8H46.7V26.5a15.64,15.64,0,0,1-11.9,5.3H24.6V25h-8l-4,6.8H0L18.5,0H34.9A16.32,16.32,0,0,1,46.8,5.3V0H57.6A12.48,12.48,0,0,1,70,12.5M34.9,27.8A11.93,11.93,0,0,0,45.7,21h5.1v6.8h2.8V21h4a8.49,8.49,0,0,0,8.5-8.5A8.58,8.58,0,0,0,57.5,4H50.7V14.2h-4A12,12,0,0,0,34.9,4h-14L7,27.8h3.3l4-6.8H28.6v6.8Zm22.6-21h-4v4h4a1.69,1.69,0,0,1,1.7,1.7,1.75,1.75,0,0,1-1.7,1.7h-4v4h4a5.7,5.7,0,0,0,5.7-5.7,5.76,5.76,0,0,0-5.7-5.7"
            />
            <path
              className="fill-white"
              d="M63.1,29.8a1.6,1.6,0,1,1-1.6-1.6,1.58,1.58,0,0,1,1.6,1.6m.4,0a2,2,0,1,0-2,2,2,2,0,0,0,2-2"
            />
            <path
              className="fill-white"
              d="M61.6,28.9a.4.4,0,1,1,0,.8h-.4v-.8Zm.5-.1a.76.76,0,0,0-.5-.2h-.8V31h.3v-.9h.4l.5.9h.4l-.6-1c.1,0,.2-.1.3-.2a.6.6,0,0,0,.1-.4.56.56,0,0,0-.1-.6"
            />
          </svg>
        </Link>
      </nav>
      <Outlet />
    </main>
  );
};

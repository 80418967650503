import { NextUIProvider } from "@nextui-org/react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { ADP } from "./pages/ADP/ADP";
import { ADPHire } from "./pages/ADP/ADPHire";
import { ADPLayout } from "./pages/ADP/ADPLayout";
import { ADPCostCalc } from "./pages/ADP/ADPCostCalc";
import { Home } from "./pages/Home/Home";
import { HireButton } from "./pages/WebComponents/HireButton";
import { CostCalculator } from "./pages/WebComponents/CostCalculator";
import { HireForm } from "./pages/WebComponents/HireForm";

function App() {
  const navigate = useNavigate();
  return (
    <NextUIProvider navigate={navigate}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="adp" element={<ADPLayout />}>
          <Route index element={<ADP />} />
          <Route path="hire" element={<ADPHire />} />
          <Route path="cost-calculator" element={<ADPCostCalc />} />
        </Route>
        <Route
          path="components"
          element={
            <main className="p-4">
              <Outlet />
            </main>
          }
        >
          <Route path="hire-button" element={<HireButton />} />
          <Route path="cost-calculator" element={<CostCalculator />} />
          <Route path="hire-form" element={<HireForm />} />
        </Route>
        <Route path="*" element="404" />
      </Routes>
    </NextUIProvider>
  );
}

export default App;

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Card, CardBody, CardHeader, Input } from "@nextui-org/react";
import "@oysterhr/web-components";
export const ADPHire = () => {
  return (
    <section className="px-8">
      <nav className="bg-blue-950 flex justify-between px-6 rounded-b-md mb-8 p-4 items-center">
        <div>
          <img src="/adp-run-logo.png" alt="adp" />
        </div>
        <div className="w-1/2">
          <Input
            radius="full"
            type="search"
            placeholder="How can we help today?"
            startContent={<MagnifyingGlassIcon className="w-5" />}
          />
        </div>
      </nav>
      <Card>
        <CardHeader className="flex-col items-start py-8">
          <h1 className="text-3xl font-semibold">Hire internationally</h1>
          <p className="text-sm text-gray-500">
            Minus deleniti repellat nihil iste dolores. Est corrupti dolore corrupti voluptatibus
            quia quo.
          </p>
        </CardHeader>
        <CardBody>
          <div className="px-8">
            <oyster-hire-form></oyster-hire-form>
          </div>
        </CardBody>
      </Card>
    </section>
  );
};
